import homeBlack from '../../../../assets/images/home-icon-png-black.png';
import homeRed from '../../../../assets/images/home-icon-png-red.png';
import productBlack from '../../../../assets/images/product-black.png';
import productRed from '../../../../assets/images/product-red.png';
import historyRed from '../../../../assets/images/nav-history-red.png';
import historyBlack from '../../../../assets/images/nav-history-black.png';
import cskhBlack from '../../../../assets/images/cskh-black.png';
import cskhRed from '../../../../assets/images/cskh-red.png';
import userBlack from '../../../../assets/images/user-icon-black.png';
import userRed from '../../../../assets/images/user-icon-red.png';
import { Link, Outlet } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import './Navbar.css';
import logo from '../../../../assets/images/central-logo.6ccc2a8734f60285ff43.png';
import { useTranslation } from 'react-i18next';

function Navbar(props) {
    let link = props.link;
    let [homeN, setHome] = useState(link === '/' ? true : false);
    let [historyN, setHistory] = useState(link === '/order/index' ? true : false);
    let [userN, setUserN] = useState(link === '/my' ? true : false);
    let [productN, setProductN] = useState(link === '/order/mission' ? true : false);
    let [cskhN, setCSKHN] = useState(link === '/' ? true : false);
    const { t } = useTranslation();

    useEffect(() => {
        if (link.indexOf('/order/mission') >= 0) {
            setHome(false);
            setHistory(false);
            setUserN(false);
            setCSKHN(false);
            setProductN(true);
        } else if (link === '/my') {
            setHome(false);
            setHistory(false);
            setUserN(true);
            setCSKHN(false);
            setProductN(false);
        } else if (link === '/system') {
            setHome(false);
            setHistory(false);
            setUserN(false);
            setCSKHN(false);
            setProductN(true);
        } else if (link === '/order/index') {
            setHome(false);
            setHistory(true);
            setUserN(false);
            setCSKHN(false);
            setProductN(false);
        } else if (link === '/') {
            setHome(true);
            setHistory(false);
            setUserN(false);
            setCSKHN(false);
            setProductN(false);
        }
    }, [link]);

    return (
        <nav className="w-full navbar__footer fixed bottom-0 left-0 z-[49]">
            <div className="flex justify-center items-center navbar__footer--container">
                <Link to="/" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            {!homeN ? (
                                <i className="flex justify-center">
                                    <img src={homeBlack} alt="Home" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={homeRed} alt="Home" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px] font-bold"
                            style={{ color: `rgb(${!homeN ? '114,114,114' : '224,227,0'})` }}
                        >
                            {t('content.nav.trangChu')}
                        </div>
                    </div>
                </Link>

                <Link to="/order/index" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            {!historyN ? (
                                <i className="flex justify-center">
                                    <img src={historyBlack} alt="History" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={historyRed} alt="History" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px] font-bold"
                            style={{ color: `rgb(${!historyN ? '114,114,114' : '224,227,0'})` }}
                        >
                            {t('content.nav.lichSu')}
                        </div>
                    </div>
                </Link>
                <Link to="/order/mission" className="flex-1 flex justify-center text-center ">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            {!productN ? (
                                <i className="flex justify-center">
                                    <img src={logo} alt="Order" className="w-16 h-16 sepia-0 mb-2 rounded-xl" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={logo} alt="Order" className="w-16 h-16 sepia-0 mb-2 rounded-xl" />
                                </i>
                            )}
                        </div>
                        {/* <div
                            className="text-xl lg:text-2xl mt-[3px] font-bold"
                            style={{ color: `rgb(${!productN ? '114,114,114' : '224,227,0'})` }}
                        >
                            Đơn hàng
                        </div> */}
                    </div>
                </Link>
                <Link to="/support" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            {!cskhN ? (
                                <i className="flex justify-center">
                                    <img src={cskhBlack} alt="CSKH" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={cskhRed} alt="CSKH" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px] font-bold"
                            style={{ color: `rgb(${!cskhN ? '114,114,114' : '224,227,0'})` }}
                        >
                            {t('content.nav.CSKH')}
                        </div>
                    </div>
                </Link>

                <Link to="/my" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            {!userN ? (
                                <i className="flex justify-center">
                                    <img src={userBlack} alt="My" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={userRed} alt="My" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px] font-bold"
                            style={{ color: `rgb(${!userN ? '114,114,114' : '224,227,0'})` }}
                        >
                            {t('content.nav.taiKhoan')}
                        </div>
                    </div>
                </Link>
            </div>
            <Outlet />
        </nav>
    );
}

export default memo(Navbar);
